import React, { useState, useEffect } from 'react';
import DirectusSDK                    from '@directus/sdk-js';
import MoreExpander                   from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';

const InfoLetters = () => {

  const client = new DirectusSDK({
    url: process.env.GATSBY_API_ENDPOINT,
    project: process.env.GATSBY_API_PROJECT
  });

  const [lettersRequest, setLettersRequest] = useState({ letters: null, loading: false });

  useEffect(() => {
    const fetchData = async () => {
      setLettersRequest({
        loading: true
      });
      const data = await client.getItems('info_letter',
        {
          sort: '-year',
          fields: '*.*.*.*'
        });
      setLettersRequest({
        loading: false,
        letters: data.data
      })
    };

    fetchData();
    //eslint-disable-next-line
  }, []);

  const { loading, letters } = lettersRequest;

  return (
    <div>
      {loading && <SpinnerContainer><Spinner /></SpinnerContainer>}
      {letters && letters.map(letter => (
        <MoreExpander key={letter.year} title={`${letter.year} Informationsbrief`}>
          <ul>
            {letter.files.map(file => (
              <li key={file.id}><Link to={file.file.data.full_url}>{file.file.title}</Link></li>
            ))}
          </ul>
        </MoreExpander>
      ))}
    </div>
  )
};


export default InfoLetters;
